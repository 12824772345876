import { combineReducers } from 'redux'
import {
    RIGHTS_CHANGE,
    SYSTEMS_CHANGE
} from './actions'

import { messages, page, support_info, user } from "@sapkk/app/reducers"

function rights(
    state = {},
    action
) {
    switch (action.type) {
        case RIGHTS_CHANGE:
            return action.rights
        default:
            return state
    }
}

function systems(
    state = {},
    action
) {
    switch (action.type) {
        case SYSTEMS_CHANGE:
            return {
                systems: action.systems,
                systems_order: action.systems_order
            }
        default:
            return state
    }
}

const appReducer = combineReducers({
    user,
    rights,
    page,
    messages,
    support_info,
    systems
})

export default appReducer
