import React, { Component } from 'react'
import { setGuide, setTitle, addMessage, clearMessages} from "../actions"
import { connect } from "react-redux"
import DashboardClient from '../DashboardClient';
import DateTimeField from '@sapkk/app/components/DateTimeField';
import Loading from "@sapkk/app/components/Loading"

class Tasks extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            loaded: false,
            system_id: this.props.match.params.system_id,
            interval: null,
            loading_more_task: [],
            tasks_number: {}
        }
    }
    
    static priority = {
        1: {alias: 'Alacsony'},
        2: {alias: 'Normál'},
        3: {alias: 'Magas'},
        4: {alias: 'Sürgős'},
        5: {alias: 'Azonnali'}
    }

    static MORE_NUM = 20;
    static INITIAL_NUM = 20;

    componentDidMount() {
        this.props.setTitle("Feladatok")
        this.props.setGuide(null)
        this.load()
    }

    static getDerivedStateFromProps(props, state) {
        if(props.match.params.system_id !== state.system_id){
            return {
                system_id: props.match.params.system_id,
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.system_id !== this.state.system_id){
            this.load()
        }
    }

    componentWillUnmount(){
        clearInterval(this.state.interval)
    }

    static processTasks = (tasks) =>{
        let system_tasks= {}
        let priority = {}
        let tasks_object = {}
        let order = []
        tasks.forEach(task =>{
            system_tasks[task.system_id] = system_tasks[task.system_id] || []
            system_tasks[task.system_id].push(task.id)
            priority[task.priority] = priority[task.priority] || []
            priority[task.priority].push(task.id)
            order.push(task.id)
            tasks_object[task.id] = task
        })
        return [system_tasks, priority, tasks_object, order]
    }

    load = () =>{
        clearInterval(this.state.interval)
        return this.loadTasks().then(newState =>{
            this.setState({
                tasks: {},
                order: [],
                system_tasks: [],
                priority: [],
                tasks_count: {},
                ...newState,
                loaded: true,
                interval: setInterval(this.loadTasksInterval, 6000)
            })
        })
    }

    loadTasksInterval = () =>{
        this.loadTasks().then(newState =>{
            this.setState({...newState})
        })
    }

    loadTasks = () =>{
        return Promise.all([
            (this.state.system_id===undefined ? DashboardClient.getMyTasks(this.state.tasks_number) : DashboardClient.getMyTasksForSystem(this.state.system_id, this.state.tasks_number[this.state.system_id]||Tasks.INITIAL_NUM)),
            DashboardClient.getMyTasksCount(this.state.system_id)
        ]).then(([tasks,tasks_count]) =>{
            let [system_tasks, priority, tasks_object, order] = Tasks.processTasks(tasks)
            if(this.state.error){
                this.props.clearMessages()
            }
            return {
                tasks: tasks_object,
                order: order,
                system_tasks: system_tasks,
                priority: priority,
                tasks_count: tasks_count,
                error: false
            }
        }).catch(error =>{
            this.props.clearMessages()
            if ((error.additional_info || []).length > 0) {
                error.additional_info.forEach(message =>
                    this.props.addMessage(message, 'error')
                )
            } else {
                this.props.addMessage(error.message, 'error')
            }
            return {
                error: true
            }
        })
    }

    loadingMoreTasks = (system_id) =>{
        let f = [...this.state.loading_more_task]
        f.push(system_id)
        clearInterval(this.state.interval)
        this.setState({
            loading_more_task: f,
            interval: null,
            tasks_number: {
                ...this.state.tasks_number,
                [system_id]: (this.state.tasks_number[system_id] || Tasks.INITIAL_NUM) + Tasks.MORE_NUM
            }
        }, ()=>{
            this.load().then(()=>{
                let f = [...this.state.loading_more_task]
                let index = f.indexOf(system_id)
                if(index!==-1){
                    f.splice(index,1)
                }
                this.setState({
                    loading_more_task: f
                })
            })
        })
    }

    render() {
        if(!this.state.loaded){
            return null
        }
        return (
            <React.Fragment>
                {
                    Object.keys(this.state.system_tasks).map(system_id =>{
                        let tasks = this.state.order.filter(task_id =>this.state.system_tasks[system_id].indexOf(task_id)!==-1)
                        this.props.systems[system_id] = this.props.systems[system_id]|| {}
                        return <React.Fragment>
                                <div className="page-subtitle">
                                    {this.props.systems[system_id].alias}
                                </div>
                                <div className="table-container">
                                    <table className="tasks-table search-table">
                                        <thead>
                                            <tr>
                                                <th className="priority">Prioritás</th>
                                                <th>Feladat neve</th>
                                                <th>Létrehozás dátuma</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tasks.map(task_id => <tr key={'task.'+this.state.group_by+'.'+system_id+'.'+task_id}>
                                                    {this.state.group_by!=='priority' ? <td className={"priority priority-"+this.state.tasks[task_id].priority}><div>{Tasks.priority[this.state.tasks[task_id].priority].alias}</div></td> : null}
                                                    <td><div>{this.state.tasks[task_id].alias}</div></td>
                                                    <td><div><DateTimeField value={this.state.tasks[task_id].created_timestamp} readOnly={true}></DateTimeField></div></td>
                                                </tr>)
                                            }
                                            {
                                                this.state.tasks_count[system_id]!=tasks.length ? <tr className="loading-more-tasks">
                                                    <td colSpan="3">{
                                                        this.state.loading_more_task.indexOf(system_id)!==-1 ? <Loading loading_text=""/> : <div onClick={()=>this.loadingMoreTasks(system_id)}>Még több feladat betöltése</div>
                                                    }</td>
                                                </tr> : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </React.Fragment>
                    })
                }
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}

function mapStateToProps(state) {
    return {
        systems: state.systems.systems,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Tasks)
