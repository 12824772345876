
export const RIGHTS_CHANGE = 'RIGHTS_CHANGE'
export const SYSTEMS_CHANGE = 'SYSTEMS_CHANGE'

export {
    setUserData,
    setTitle,
    setGuide,
    addMessage,
    clearMessages,
    setSupportMail,
    setUserManualURL,
    setPrivacyPolicyURL
} from "@sapkk/app/actions"

export function setRights(rights) {
    return {
        type: RIGHTS_CHANGE,
        rights: rights
    }
}

export function setSystems(systems) {
    return {
        type: SYSTEMS_CHANGE,
        systems_order: systems.map(system => system.id),
        systems: systems.reduce((prev, curr) => ({...prev, [curr.id] : curr}), {})
    }
}
