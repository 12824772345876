import React, { Component } from 'react'

class WidgetBase extends Component {
    constructor(props) {
        super(props)
    }
   
    render() {
        return (            
                <div className="widget"  style={{
                    gridColumn: 'span '+ this.props.width,
                    gridRow: 'span '+ this.props.height
                }} {...this.props}>
                    <div className="widget-content">
                    {this.props.id}
                    {this.props.data!==undefined && this.props.children}
                    </div>
                </div>
        )
    }
}

export default WidgetBase
