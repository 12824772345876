import React, { Component } from 'react'
import Loading from './Loading';
import Error from './Error';

class Widget extends Component {
    constructor(props) {
        super(props)
        this.state = {
            module: null,
            error: false
        }
    }

    componentDidMount(){
        let module_name = this.props.widget_data.name.replace(/(\_\w)/g, function(m){
            return m[1].toUpperCase();
        })
        module_name = module_name.charAt(0).toUpperCase() + module_name.slice(1)
        import('./UserDefined/'+module_name)
            .then(module => this.setState({ module: module.default })).catch((error)=>{
                this.setState({
                    error: true
                })
            })
    }

    render() {
        if(this.state.error || this.props.status=='FAILED'){
            return <Error {...this.props}/>
        }
        if(!this.state.module || typeof this.state.module === 'object'){
            return <Loading {...this.props}/>
        }
        const Module = this.state.module
        return <Module {...this.props}/>
    }
}

export default Widget
