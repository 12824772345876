
class DashboardClient {

    static getSupportInfo() {
        return fetch(process.env.REACT_APP_SERVICE_URL + '/DashboardService/Support/getInfo', {
            credentials: 'include',
            method: "post"
        }).catch(() => {
            throw new Error('Nem várt hiba történt.')
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.json().then(json => {
                    throw new Error(json.message || 'Nem várt hiba történt.')
                })
            }
        })
    }

    static getRights() {
        return fetch(process.env.REACT_APP_SERVICE_URL + '/DashboardService/User/getRights', {
            credentials: 'include',
            method: "post"
        }).catch(() => {
            throw new Error('Nem várt hiba történt.')
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.json().then(json => {
                    throw new Error(json.message || 'Nem várt hiba történt.')
                })
            }
        })
    }

    static getSystems(){
        return fetch(process.env.REACT_APP_SERVICE_URL + '/DashboardService/System/getSystems', {
            credentials: 'include',
            method: "post"
        }).catch(() => {
            throw new Error('Nem várt hiba történt.')
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.json().then(json => {
                    throw new Error(json.message || 'Nem várt hiba történt.')
                })
            }
        })
    }

    static getFavorities(){
        return fetch(process.env.REACT_APP_SERVICE_URL + '/DashboardService/System/getFavorities', {
            credentials: 'include',
            method: "post"
        }).catch(() => {
            throw new Error('Nem várt hiba történt.')
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.json().then(json => {
                    throw new Error(json.message || 'Nem várt hiba történt.')
                })
            }
        })
    }

    static favoriting(system_id){
        return fetch(process.env.REACT_APP_SERVICE_URL + '/DashboardService/System/favoriting', {
            credentials: 'include',
            method: "post",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                system_id: system_id
            })
        }).catch(() => {
            throw new Error('Nem várt hiba történt.')
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.json().then(json => {
                    throw new Error(json.message || 'Nem várt hiba történt.')
                })
            }
        })
    }
    static unfavoriting(system_id){
        return fetch(process.env.REACT_APP_SERVICE_URL + '/DashboardService/System/unfavoriting', {
            credentials: 'include',
            method: "post",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                system_id: system_id
            })
        }).catch(() => {
            throw new Error('Nem várt hiba történt.')
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.json().then(json => {
                    throw new Error(json.message || 'Nem várt hiba történt.')
                })
            }
        })
    }

    static tasks = {
        1: [
            {id: 852, name: "", priority: 3, creation: '2019-03-12',system_id: 1}
        ],
        2: ['01','02','03','04','05','06','07','08','0Az UD Tutkó 2019. 1. negyedéves beszámolójának kitöltése9',10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31].map(day =>[
            {id: 625+2*day, name: "2019. 05. "+day+". PharMagic állományok feldolgozásra való kiválasztása", priority: 5, creation: '2019-05-'+day,system_id: 2},
            {id: 626+2*day, name: "2019. 05. "+day+". Gyurika állományok feldolgozásra való kiválasztása", priority: 5, creation: '2019-05-'+day,system_id: 2},
        ]).reduce((prev, curr) => prev.concat(curr),[])
    }

    static getMyTasks(task_number = {}){
        return fetch(process.env.REACT_APP_SERVICE_URL + '/DashboardService/Task/getMyTasks', {
            credentials: 'include',
            method: "post",
            body: JSON.stringify({
                task_number: task_number
            })
        }).catch(() => {
            throw new Error('Nem várt hiba történt.')
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.json().then(json => {
                    throw new Error(json.message || 'Nem várt hiba történt.')
                })
            }
        })
    }

    static getMyTasksCount(system_id=undefined){
        return fetch(process.env.REACT_APP_SERVICE_URL + '/DashboardService/Task/getMyTasksCount', {
            credentials: 'include',
            method: "post",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                system_id: system_id
            })
        }).catch(() => {
            throw new Error('Nem várt hiba történt.')
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.json().then(json => {
                    throw new Error(json.message || 'Nem várt hiba történt.')
                })
            }
        }).catch(() =>{
        }).then(async ()=>{
            await new Promise(resolve => setTimeout(resolve, 1000))
            return Object.keys(DashboardClient.tasks).filter(id => system_id===undefined || system_id===id).reduce((prev, curr) =>({...prev,[curr]: DashboardClient.tasks[curr].length}),{})
        })
        
    }

    static getMyTasksForSystem(system_id, task_number = 20){
        return fetch(process.env.REACT_APP_SERVICE_URL + '/DashboardService/Task/getMyTasksForSystem', {
            credentials: 'include',
            method: "post",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                system_id: system_id,
                task_number: task_number
            })
        }).catch(() => {
            throw new Error('Nem várt hiba történt.')
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.json().then(json => {
                    throw new Error(json.message || 'Nem várt hiba történt.')
                })
            }
        })
    }

    static getWidgets(){
        return fetch(process.env.REACT_APP_SERVICE_URL + '/DashboardService/Widget/getList', {
            credentials: 'include',
            method: "post",
        }).catch(() => {
            throw new Error('Nem várt hiba történt.')
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.json().then(json => {
                    throw new Error(json.message || 'Nem várt hiba történt.')
                })
            }
        })
    }

    static getMyWidgetsInfo(){
        return fetch(process.env.REACT_APP_SERVICE_URL + '/DashboardService/Widget/getMyWidgetsInfo', {
            credentials: 'include',
            method: "post",
        }).catch(() => {
            throw new Error('Nem várt hiba történt.')
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.json().then(json => {
                    throw new Error(json.message || 'Nem várt hiba történt.')
                })
            }
        })
    }

    static getAccessableWidgetsList(){
        return fetch(process.env.REACT_APP_SERVICE_URL + '/DashboardService/Widget/getAccessableWidgetList', {
            credentials: 'include',
            method: "post",
        }).catch(() => {
            throw new Error('Nem várt hiba történt.')
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.json().then(json => {
                    throw new Error(json.message || 'Nem várt hiba történt.')
                })
            }
        })
    }

    static saveEditedWidgets(new_user_widgets, deleteable_user_widgets, edit_user_widgets){
        return fetch(process.env.REACT_APP_SERVICE_URL + '/DashboardService/Widget/editUserWidgets', {
            credentials: 'include',
            method: "post",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                new_user_widgets: new_user_widgets,
                deleteable_user_widgets:deleteable_user_widgets,
                edit_user_widgets: edit_user_widgets
            })
        }).catch(() => {
            throw new Error('Nem várt hiba történt.')
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.json().then(json => {
                    throw new Error(json.message || 'Nem várt hiba történt.')
                })
            }
        })
    }
}

export default DashboardClient
