import React, { Component } from 'react'
import { Route, Switch } from "react-router-dom"
import Welcome from "./Welcome"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import {
    clearMessages,
    setRights,
    setUserData,
    setSupportMail,
    setUserManualURL,
    setPrivacyPolicyURL,
    setSystems
} from "../actions"
import AuthClient from "@sapkk/auth-client"
import SapkkApp from "@sapkk/app/containers/App"
import SapkkLogin from "@sapkk/app/containers/Login"
import Dashboard from './Dashboard'
import DashboardClient from "../DashboardClient"

import "../static/css/style.css"
import Tasks from './Tasks';
import Profil from './Profil';
import EditWidgets from './EditWidgets';
import Systems from './Systems';
import PopUp from '../components/PopUp'

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            key: 0,
            freeze: true,
            browser_popup:  !!document.documentMode
        }

        this.props.setSupportMail(false, "helpdesk@sapkk.unideb.hu")
        this.props.setPrivacyPolicyURL("https://saportal2.fin.unideb.hu/portal/system/files/documents/SAPKK_adatkezelesi_tajekoztato.pdf")
    }

    componentDidMount() {
        Promise.all([
            DashboardClient.getSupportInfo().then(support_info => {
                this.props.setSupportMail(support_info.mail_form, support_info.mail_address)
                this.props.setUserManualURL(support_info.user_manual)
                this.props.setPrivacyPolicyURL(support_info.privacy_policy)
            }).catch(() => {
                this.props.setSupportMail(false, undefined)
                this.props.setUserManualURL(undefined)
                this.props.setPrivacyPolicyURL(undefined)
            }),
            DashboardClient.getSystems().then(systems =>{
                this.props.setSystems(systems)
            }).catch(() => {
                this.props.setSystems([])
            })
        ]).then(() => {
            this.setState({
                freeze: false
            })
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.location.pathname !== prevProps.location.pathname) {
            this.props.clearMessages()
        }
        if(this.props.messages_updated !== prevProps.messages_updated) {
            window.scrollTo(0, 0)
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !nextState.freeze
    }

    handleLogin = (user_data, method)=>{
        return AuthClient.elevate('login', user_data).then(() =>
            AuthClient.checkAuth().then(user_data =>
                method(user_data)
            )
        )
    }

    handleLogout() {
        AuthClient.logout().then(() => {
            this.props.setUserData({id: 0})
            this.props.history.push({
                pathname: '/'
            })
        })
    }

    incrementKey(path){
        if(this.props.history.location.pathname==path){
            this.setState((prevState=>({
                key: prevState.key + 1
            })))
        }
    }

    handleBrowserPopupClose = ()=>{
        this.setState({
            browser_popup: false
        })
    }

    render() {
        if (this.state.freeze) {
            return null
        }
        return (
            <SapkkApp title="SAP Kompetencia Központ" menuItems={[
//                {to: "/", name: "Kezdőoldal"},
                {to: "/", name: "Alkalmazások"},
                //this.props.user.id ? {to: "/tasks", name: "Feladatok"} : null
                ].filter(val => val)} onMenuClick={(info) => this.incrementKey(info.to)} logout={() => this.handleLogout()}>
                { this.state.browser_popup && (
                    <PopUp title={'Elavult böngésző!'} close={this.handleBrowserPopupClose} buttons={[{action: this.handleBrowserPopupClose, text: 'Ok'}]} contentClass="description">
                        <p>Javasoljuk az oldal megnyitását más böngészőben, mert az elavult böngészője nem megfelelően jeleníti meg az oldal tartalmát, továbbá a használat közben hibák fordulhatnak elő.</p>
                        <p style={{marginBottom: '5px'}}>Javasolt böngészőprogramok:</p>
                        <ul>
                            <li>Google Chrome</li>
                            <li>Microsoft Edge</li>
                            <li>Mozilla Firefox</li>
                        </ul>
                    </PopUp>
                ) }
                <Switch key={'content.'+this.state.key}>
                    <Route path='*' component={Systems}/>
                </Switch>
            </SapkkApp>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        rights: state.rights,
        page: state.page,
        messages_updated: state.messages.updated,
        messages: state.messages.messages,
        support_info: state.support_info,
        systems: state.systems.systems,
    }
}

const mapDispatchToProps = {
    setUserData: setUserData,
    setRights: setRights,
    clearMessages: clearMessages,
    setSupportMail: setSupportMail,
    setUserManualURL: setUserManualURL,
    setPrivacyPolicyURL: setPrivacyPolicyURL,
    setSystems: setSystems
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
