import React, { Component } from 'react'

class PopUp extends Component {

    constructor(props) {
        super(props)
   }

    handleKeyEvent = (event) => {
        event.stopPropagation()
        if(event.which == 27){
            this.props.close()
        }
    }

    render() {
        return <div className="popup-container" onClick={this.props.close} onKeyDown={this.handleKeyEvent}>
            <div className="popup" onClick={event => event.stopPropagation()} tabIndex='1'>
                {this.props.title ? <div className="popup-title">
                    {this.props.title}
                </div> : null}
                <div className={"popup-content "+(this.props.contentClass||'')}>
                    {this.props.children}
                </div>
                <div className="popup-actions">
                    {
                        (this.props.buttons||[]).map(button_info => <button onClick={button_info.action || null} className={button_info.className || null} >{button_info.text || null}</button>)
                    }
                </div>
            </div>
        </div>
    }
}

export default PopUp
