import React, { Component } from 'react'
import { setGuide, setTitle } from "../actions"
import { connect } from "react-redux"

class Welcome extends Component {
    componentDidMount() {
        this.props.setTitle("Kezdőoldal")
        this.props.setGuide(null)
    }

    render() {
        return (
            <React.Fragment>
                <div className="welcome-description">
                    <h3 className="welcome">Üdvözöljük az SAP Kompetencia Központ oldalán!</h3>
                </div>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide
}

export default connect(null, mapDispatchToProps)(Welcome)
