import React, { Component } from 'react'
import WidgetBase from './WidgetBase';

class Error extends Component {

    static height = 1;
    static width = 1;

    constructor(props) {
        super(props)
    }

    render() {
        return <WidgetBase {...this.props} height={Error.height} width={Error.width}>
            <div>Hiba</div>
        </WidgetBase>
    }
}

export default Error