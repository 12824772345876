import React, { Component } from 'react'
import { setGuide, setTitle, clearMessages, addMessage } from "../actions"
import { connect } from "react-redux"
import DashboardClient from '../DashboardClient';
import SelectField from '@sapkk/app/components/SelectField';
import PopUpFlatSelector from '@sapkk/app/components/PopUpFlatSelector';
import SimpleField from '@sapkk/app/components/SimpleField';
import { setToValue, getObjectValue } from '../functions';

class EditWidgets extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loaded: false,
            new_user_widgets: [],
            deleted_user_widgets: [],
            edited_user_widgets: {},
            mouse_position: undefined,
            user_widgets: []
        }
    }

    componentDidMount() {
        this.props.setTitle('Minialkalmazások szerkesztése')
        this.props.setGuide(null)
        this.loadWidgets()
    }

    errorHandler = (error) => {
        this.props.clearMessages()
        if ((error.additional_info || []).length > 0) {
            error.additional_info.forEach(message =>
                this.props.addMessage(message, 'error')
            )
        } else {
            this.props.addMessage(error.message, 'error')
        }
        this.setState({
            error: true
        })
    }

    loadWidgets = () => {
        Promise.all([
            DashboardClient.getAccessableWidgetsList(),
            DashboardClient.getMyWidgetsInfo(),
        ]).catch(error => {
            this.errorHandler(error)
            return [[], {}]
        }).then(([widgets, user_widgets]) => {
            this.setState({
                widgets: widgets,
                user_widgets: user_widgets,
                loaded: true
            })
        })
    }

    openPopupForCreateWidget = () => {
        this.setState({
            popup: true,
            edit_widget: null,
            popup_data: {
                parameters: {}
            }
        })
    }

    openPopupForEditWidget = (user_widget_id) => {
        this.setState((prevState) => {
            let widget = prevState.user_widgets.find(uw => uw.id === user_widget_id)
            return {
                popup: true,
                edit_widget: user_widget_id,
                popup_data: {
                    parameters: {
                        ...Object.keys(widget.parameters).reduce((prev, curr) => {
                            let values = widget.parameters[curr].values
                            return { ...prev, [curr]: values }
                        }, {}),
                        ...(prevState.edited_user_widgets[user_widget_id] || { parameters: {} }).parameters
                    }
                }
            }
        })
    }

    openPopupForEditNewWidget = (index) => {
        this.setState(prevState => ({
            popup: true,
            edit_widget: null,
            popup_data: {
                ...this.state.new_user_widgets[index]
            }
        }))
    }

    deleteExistingWidget = (user_widget_id) => {
        this.setState(prevState => {
            let f = [...prevState.deleted_user_widgets]
            f.push(user_widget_id)
            return {
                deleted_user_widgets: f
            }
        })
    }

    deleteNewlyCreatedWidget = (index) => {
        this.setState(prevState => {
            let f = [...prevState.new_user_widgets]
            f.splice(index, 1)
            return {
                new_user_widgets: f
            }
        })
    }

    changePopup = (event) => {
        let name = event.target.name
        let value = event.target.value
        this.setState((prevState) => {
            let f = { ...prevState.popup_data }
            if (value === undefined) {
                let path = name.split('.')
                let subobj = getObjectValue(f, path.slice(0, path.length - 1))
                if(Array.isArray(subobj)){
                    subobj.splice(path[path.length - 1],1)
                } else {
                    delete subobj[path[path.length - 1]]
                }
            } else {
                setToValue(f, value, name.split('.'))
            }
            return {
                popup_data: f,
                flat_selector: false,
                hier_selector: false
            }
        })
    }

    closePopUp = () => {
        this.setState({
            popup: false,
            edit_widget: null,
        })
    }

    flatSelectorOpen = () => {
        this.setState({
            flat_selector: true,
            flat_key: (this.state.flat_key || 0) + 1
        })
    }

    flatSelectorClose = () => {
        this.setState({
            flat_selector: false
        })
    }

    hierSelectorOpen = () => {
        this.setState({
            hier_selector: true,
            hier_key: (this.state.hier_key || 0) + 1
        })
    }

    hierSelectorClose = () => {
        this.setState({
            hier_selector: false
        })
    }

    selectParameterValueFromPopUp = (parameter, index) => {
        return (row) =>
            this.changePopup({
                target: {
                    name: 'parameters.' + parameter.name + (parameter.multiple ? '.' + index : ''),
                    value: row.key
                }
            })
    }

    deselectParameterValueFromTable = (parameter, index) => {
        return (row) => this.changePopup({
            target: {
                name: 'parameters.' + parameter.name + (parameter.multiple ? '.' + index : ''),
                value: undefined
            }
        })
    }

    generateParameterValueSetter(param, value, readOnly, index) {
        let props = {
            name: 'parameters.' + param.name + (param.multiple ? '.' + index : ''),
            value: value,
            readOnly: readOnly
        }
        let classes = ['field', 'field_simple']
        switch (param.type) {
            case 'STRING':
                return <SimpleField {...props} className={classes.join(' ')} maxLength={128} />
            case 'INT':
                return <SimpleField {...props} className={classes.join(' ')} pattern="-?\d*" maxLength={128} />
            case 'LIST':
                return <SelectField {...props} className={classes.join(' ')} displayValues={param.possible_values.reduce((prev, curr) => ({ ...prev, [curr.key]: curr.alias }), {})}>
                    <option value=''>Nincs megadva</option>
                    {
                        param.possible_values.map(possible => <option value={possible.key} />)
                    }
                </SelectField>
            case 'SQL_FLAT':
            case 'SQL_HIER':
                var possible_values = param.possible_values
                var choosable_values = possible_values
                if (param.multiple) {
                    choosable_values = possible_values.filter(possible => {
                        return !Object.keys(this.state.popup_data.parameters[param.name] || {}).some(my_index => my_index !== index && this.state.popup_data.parameters[param.name][my_index] === possible.key)
                    })
                }
                return Object.keys(choosable_values).length === 0 ? <div className="field field-simple">Nem adhat meg értéket</div> : <React.Fragment>
                    <div className={!param.multiple ? "input-ujx" : null}>
                        {value !== undefined ?
                            <React.Fragment>
                                <div>{(param.possible_values.find(possible => ''+possible.key === ''+value) || {}).alias || value}</div>
                                {!param.multiple ? <button disabled={readOnly} onClick={this.deselectParameterValueFromTable(param)}>X</button> : null}
                            </React.Fragment>
                            : <React.Fragment>
                                <div>Új érték felvétele</div>
                                {!param.multiple ? <button disabled={readOnly} onClick={param.type == 'SQL_FLAT' ? this.flatSelectorOpen : (param.type == 'SQL_HIER' ? this.hierSelectorOpen : null)}>
                                    ...
                                </button> : null}
                            </React.Fragment>
                        }
                    </div>
                    {
                        param.type == 'SQL_FLAT' && <PopUpFlatSelector key={'flat_popup.' + this.state.flat_key}
                            selector_opened={this.state.flat_selector}
                            data={choosable_values}
                            closeFlatSelector={this.flatSelectorClose}
                            main_fields={['alias']}
                            select={this.selectParameterValueFromPopUp(param, index)} />
                    }
                </React.Fragment>
            default:
                return <SimpleField {...props} className={classes.join(' ')} />
        }
    }

    saveModification = () => {
        this.setState((prevState) => {
            let new_user_widgets = prevState.new_user_widgets
            let edited_user_widgets = prevState.edited_user_widgets
            let widget_id = prevState.popup_data.widget_id || this.state.user_widgets.find(uw => uw.id == prevState.edit_widget).widget_data.id
            let data = {
                ...prevState.popup_data,
                name: prevState.widgets.find(widget => widget.id ==widget_id).name
            }
            if (prevState.edit_widget) {
                edited_user_widgets[prevState.edit_widget] = data
            } else {
                new_user_widgets.push({
                    ...data,
                    rank: new_user_widgets.length + prevState.user_widgets.length
                })
            }
            return {
                flat_selector: false,
                hier_selector: false,
                new_user_widgets: new_user_widgets,
                edited_user_widgets: edited_user_widgets,
            }
        }, this.closePopUp)
    }

    save = () => {
        this.setState({
            save_in_progress: true
        }, () => {
            DashboardClient.saveEditedWidgets(this.state.new_user_widgets, this.state.deleted_user_widgets, this.state.user_widgets.reduce((prev, curr) =>{
                return {
                    ...prev,
                    [curr.id]: {
                        rank: curr.rank,
                        id: curr.id,
                        ...(this.state.edited_user_widgets[curr.id] || {})
                    }
                }
            },{})).then((user_widgets)=>{
                this.setState({
                    user_widgets: user_widgets,
                    new_user_widgets: [],
                    edited_user_widgets: {},
                    deleted_user_widgets: []
                })
            }).catch(this.errorHandler)
            .then(()=>{
                this.setState({
                    save_in_progress: false
                })
            })
        })
    }

    dragMouseDown = (e) => {
        let id = e.target.id
        e.dataTransfer.setData("text", id)
        setTimeout(()=>{
            document.ondragend = this.closeDragElement
            this.setState({
                sorting: id,
            })
        })        
    }


    getWidgets = (user_widgets, new_user_widgets) => {
        return user_widgets.map((widget, index) => {
            return {
                type: 'user_widget',
                id: widget.id,
                rank: widget.new_rank !== undefined ? widget.new_rank : widget.rank,
                index: index,
                widget: widget
            }
        }).concat(new_user_widgets.map((widget, index) => {
            return {
                type: 'new_user_widget',
                index: index,
                rank: widget.new_rank !== undefined ? widget.new_rank : widget.rank,
                widget: widget
            }
        }))
    }

    sortWidgets = (user_widgets, new_user_widgets) => {
        return this.getWidgets(user_widgets, new_user_widgets).sort((a, b) => {
            if (a.top > b.top) {
                return 1
            } else if (a.top < b.top) {
                return -1
            } else if (a.rank > b.rank) {
                return 1
            } else if (a.rank < b.rank) {
                return -1
            } else {
                return 0
            }
        })
    }

    closeDragElement = () => {
        document.ondragend = null

        if (this.state.mouse_position !== undefined) {
            this.setState((prevState) => {
                let f = [...prevState.user_widgets]
                let g = [...prevState.new_user_widgets]
                let widgets = this.sortWidgets(f, g)
                let id = prevState.mouse_position
                let widget = id === null ? {
                    rank: f.length + g.length
                } : widgets.find((val, index) => {
                    return val.type + '.' + (val.id || val.index) === id
                })
                widgets.map((widget_info, rank_index) => {
                    let index = (widget_info.type +'.' +(widget_info.id || widget_info.index)) === prevState.sorting 
                    ? widget.rank 
                    : rank_index + (rank_index >= widget.rank ? 1 : 0)
                    if (widget_info.type == 'user_widget') {
                        f[widget_info.index].rank = index
                    } else if (widget_info.type == 'new_user_widget') {
                        g[widget_info.index].rank = index
                    }
                })
                return {
                    user_widgets: f,
                    new_user_widgets: g,
                    mouse_position: id,
                    sorting: false,
                    mouse_position: undefined
                }
            })
        }
    }

    mouseEntered = (id) => {
        if (this.state.sorting && this.state.sorting !== id) {
            this.setState({
                mouse_position: id
            })
        }
    }

    render() {
        if (!this.state.loaded) {
            return null
        }
        let readOnly = this.state.save_in_progress

        return <React.Fragment>
            {this.state.popup && (() => {
                let widget_id = this.state.edit_widget ? (this.state.user_widgets.find(uw => uw.id == this.state.edit_widget) || { widget_data: {} }).widget_data.id : this.state.popup_data.widget_id
                let widget = this.state.widgets.find((widget) => widget.id == widget_id)
                return <div className="popup-container" onClick={this.closePopUp}>
                    <div className="popup" onClick={event => event.stopPropagation()} onChange={this.changePopup}>
                        <div className="block">
                            <div className="page-subtitle">{this.state.edit_widget ? 'Widget szerkesztése' : 'Új widget hozzáadása'}</div>
                            <div className="block">
                                <label className={"label label-long" + (this.state.edit_widget ? '' : ' required')}>Típusa</label>
                                <SelectField value={widget_id} className="field field-simple" name="widget_id" readOnly={readOnly || this.state.edit_widget} displayValues={this.state.widgets.reduce((prev, curr) => ({ ...prev, [curr.id]: curr.alias }), {})}>
                                    <option value=''>Nincs megadva</option>
                                    {
                                        this.state.widgets.map(widget => <option value={widget.id} />)
                                    }
                                </SelectField>
                            </div>
                            {widget ? <React.Fragment>
                                <div className="block">
                                    <label className="label label-long">Leírás</label>
                                    <div className="field field-text">
                                        {widget.description}
                                    </div>
                                </div>
                                {Object.keys(widget.parameters).map(parameter_name => {
                                    let parameter = widget.parameters[parameter_name]
                                    return <div className="block">
                                        <label className={"label label-long" + (parameter.required ? ' required' : '')}>{parameter.alias}</label>
                                        <div className="full-input">
                                            {
                                                parameter.multiple ? (
                                                    <div className="table-container">
                                                        <table>
                                                            <tbody>
                                                                {
                                                                    Object.keys(this.state.popup_data.parameters[parameter.name] || {}).map((index) => {
                                                                        return <tr>
                                                                            <td>{this.generateParameterValueSetter(parameter, this.state.popup_data.parameters[parameter.name][index], readOnly, index)}</td>
                                                                            <td className="col-actions"><button disabled={readOnly} className="remove" onClick={this.deselectParameterValueFromTable(parameter, index)} /></td>
                                                                        </tr>
                                                                    })
                                                                }
                                                                {!readOnly ? <tr>
                                                                    <td>
                                                                        {
                                                                            this.generateParameterValueSetter(parameter, '', readOnly, Object.keys(this.state.popup_data.parameters[parameter.name] || {}).length)
                                                                        }
                                                                    </td>
                                                                    {
                                                                        (parameter.type === 'SQL_FLAT' || parameter.type === 'SQL_HIER') && <td className="col-actions">
                                                                            <button disabled={readOnly} className="add" onClick={parameter.type === 'SQL_HIER' ? this.hierSelectorOpen : this.flatSelectorOpen} />
                                                                        </td>
                                                                    }
                                                                </tr> : null}
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                ) : this.generateParameterValueSetter(parameter, this.state.popup_data.parameters[parameter.name], readOnly)
                                            }
                                        </div>
                                    </div>
                                })}
                                <div className="actions">
                                    <button disabled={readOnly} onClick={this.saveModification}>Mentés</button>
                                </div>
                            </React.Fragment> : null}
                        </div>
                    </div>
                </div>
            })()
            }
            <div className="table-container">
                <table className="modify-table" style={{ position: 'relative' }} id='widget-list'>
                    <thead>
                        <tr>
                            <th>Widget</th>
                            <th>Leírás</th>
                            <th className="col-actions"></th>
                            <th className="col-actions"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.sortWidgets(this.state.user_widgets, this.state.new_user_widgets).map(widget_rank => {
                                let widget = widget_rank.widget
                                if (widget_rank.type == 'user_widget') {
                                    let act_id = 'user_widget.' + widget.id
                                    let deleted = this.state.deleted_user_widgets.indexOf(widget.id)!==-1
                                    return <React.Fragment>
                                        {this.state.mouse_position == act_id ? <tr><td colSpan={2}></td></tr> : null}
                                        <tr {...(deleted ? {className: 'removed-widget'}: {
                                            ...(this.state.sorting===act_id ? {style: {display: 'none'}}: {}),
                                            draggable:true,
                                            onDragStart: this.dragMouseDown,
                                            ...(this.state.edited_user_widgets[widget.id] ? {className: 'edited-widget'} : {})
                                        })} onDragOver={() => this.mouseEntered(act_id)} id={act_id} key={act_id}>
                                            <td>{widget.widget_data.alias} - {widget.description} {this.state.edited_user_widgets[widget.id] ? '(Módosult)' : null}</td>
                                            <td>{widget.widget_data.description}</td>
                                            <td className="col-actions"><button disabled={readOnly} className="edit" onClick={() => this.openPopupForEditWidget(widget.id)} /></td>
                                            <td className="col-actions"><button disabled={readOnly} className="remove" onClick={()=> this.deleteExistingWidget(widget.id)}/></td>
                                        </tr>
                                    </React.Fragment>
                                } else {
                                    let widget_info = this.state.widgets.find(w => w.id == widget.widget_id ||  w.id ==(widget.widget_data||{}).id) || {}
                                    let index = widget_rank.index                                    
                                    let act_id = 'new_user_widget.' + index
                                    let description = ''
                                    if ((widget_info.parameters[widget_info.description_parameter].type === 'LIST' ||
                                        widget_info.parameters[widget_info.description_parameter].type === 'SQL_FLAT' ||
                                        widget_info.parameters[widget_info.description_parameter].type === 'SQL_HIER') && widget_info.parameters[widget_info.description_parameter].possible_values !== null) {
                                        let possible_values = widget_info.parameters[widget_info.description_parameter].possible_values
                                        if (Array.isArray(widget.parameters[widget_info.description_parameter])) {
                                            description = widget_info.parameters[widget_info.description_parameter].map(param_value => {
                                                return possible_values.find(possible => possible.key == param_value).alias
                                            }).join(', ')
                                        } else {
                                            description = possible_values.find(possible => possible.key == widget.parameters[widget_info.description_parameter]).alias
                                        }
                                    } else {
                                        description = Array.isArray(widget.parameters[widget_info.description_parameter]) ? widget.parameters[widget_info.description_parameter].join(', ') : widget.parameters[widget_info.description_parameter]
                                    }
                                    return <React.Fragment>
                                        {this.state.mouse_position == act_id ? <tr><td colSpan={2}></td></tr> : null}
                                        <tr draggable={true} style={this.state.sorting===act_id ? {display: 'none'}: {}} onDragOver={() => this.mouseEntered(act_id)} onDragStart={this.dragMouseDown} id={act_id} key={act_id}>
                                            <td>{widget_info.alias} - {description} (Új)</td>
                                            <td>{widget_info.description}</td>
                                            <td className="col-actions"><button disabled={readOnly} className="edit" onClick={() => this.openPopupForEditNewWidget(index)} /></td>
                                            <td className="col-actions"><button  disabled={readOnly} className="remove" onClick={()=>this.deleteNewlyCreatedWidget(index)}/></td>
                                        </tr>
                                    </React.Fragment>
                                }
                            })
                        }
                        {this.state.mouse_position === null ? <tr><td colSpan={2}></td></tr> : null}
                        <tr className="table-new-row" onDragOver={() => this.mouseEntered(null)}>
                            <td colSpan={2}> Új widget felvétele</td>
                            <td></td>
                            <td className="col-actions"><button className="add" disabled={readOnly} onClick={this.openPopupForCreateWidget} /></td>
                        </tr>
                    </tbody>
                </table>
                <div className="actions">
                    <button onClick={this.save} disabled={readOnly}>Mentés</button>
                </div>
            </div>
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(EditWidgets)
