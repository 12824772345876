import React, { Component } from 'react'
import WidgetBase from './WidgetBase';

class Loading extends Component {

    static height = 1;
    static width = 1;

    constructor(props) {
        super(props)
    }

    render() {
        return <WidgetBase {...this.props} height={Loading.height} width={Loading.width}>
            <div>Betöltés</div>
        </WidgetBase>
    }
}

export default Loading