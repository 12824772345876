import React, { Component } from 'react'
import { setGuide, setTitle, clearMessages, addMessage} from "../actions"
import { connect } from "react-redux"
import DashboardClient from '../DashboardClient';
import Widget from './Widget/Widget';
import { Link } from "react-router-dom"

class Dashboard extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            loaded: false
        }
        this.socket = new WebSocket(process.env.REACT_APP_WEBSOCKET+'/dashboard/widget/userWidgets');
        this.socket.onmessage = this.handleData; 
    }

    componentDidMount() {
        this.props.setTitle('Kezdőoldal')
        this.props.setGuide(null)
        this.load()
    }

    componentWillUnmount(){
        clearInterval(this.state.interval)
    }

    errorHandler = (error) =>{
        this.props.clearMessages()
        if ((error.additional_info || []).length > 0) {
            error.additional_info.forEach(message =>
                this.props.addMessage(message, 'error')
            )
        } else {
            this.props.addMessage(error.message, 'error')
        }
        this.setState({
            error: true
        })
    }

    
    load = () => {
        this.loadWidgets().then(()=>{
            this.setState({
                interval: setInterval(this.loadWidgets, 60000)
            })
        })
    }

    loadWidgets = () =>{
        return Promise.all([
            DashboardClient.getWidgets()
        ]).catch(error => {
            this.errorHandler(error)
            return [[],{}]
        }).then(([widgets]) => {
            this.setState({
                widgets: widgets,
                new_widgets: [],
                deleted_user_widgets: [],
                edited_user_widgets: {},
                loaded: true
            })
        })
    }

    dragMouseDown = (e) => {
        let id = e.target.id
        e.dataTransfer.setData("text", id)
        setTimeout(()=>{
            document.ondragend = this.closeDragElement
            this.setState({
                sorting: id,
            })
        })        
    }


    getWidgets = (widgets, new_widgets) => {
        return widgets.map((widget, index) => {
            return {
                type: 'widget',
                id: widget.id,
                rank: widget.new_rank !== undefined ? widget.new_rank : widget.rank,
                index: index,
                widget: widget
            }
        }).concat(new_widgets.map((widget, index) => {
            return {
                type: 'new_widget',
                index: index,
                rank: widget.new_rank !== undefined ? widget.new_rank : widget.rank,
                widget: widget
            }
        }))
    }

    sortWidgets = (widgets, new_widgets) => {
        return this.getWidgets(widgets, new_widgets).sort((a, b) => {
            if (a.top > b.top) {
                return 1
            } else if (a.top < b.top) {
                return -1
            } else if (a.rank > b.rank) {
                return 1
            } else if (a.rank < b.rank) {
                return -1
            } else {
                return 0
            }
        })
    }

    closeDragElement = () => {
        document.ondragend = null

        if (this.state.mouse_position !== undefined) {
            this.setState((prevState) => {
                let f = [...prevState.widgets]
                let g = [...prevState.new_widgets]
                let widgets = this.sortWidgets(f, g)
                let id = prevState.mouse_position
                let widget = id === null ? {
                    rank: f.length + g.length
                } : widgets.find((val, index) => {
                    return val.type + '.' + (val.id || val.index) === id
                })
                widgets.map((widget_info, rank_index) => {
                    let index = (widget_info.type +'.' +(widget_info.id || widget_info.index)) === prevState.sorting 
                    ? widget.rank 
                    : rank_index + (rank_index >= widget.rank ? 1 : 0)
                    if (widget_info.type == 'widget') {
                        f[widget_info.index].rank = index
                    } else if (widget_info.type == 'new_widget') {
                        g[widget_info.index].rank = index
                    }
                })
                return {
                    widgets: f,
                    new_widgets: g,
                    mouse_position: id,
                    sorting: false,
                    mouse_position: undefined
                }
            })
        }
    }

    mouseEntered = (id, next_id) => {
        if (this.state.sorting && this.state.sorting !== id) {
            console.log(id, next_id, this.state.sorting, this.state.mouse_position)
            this.setState((prevState) =>({
                mouse_position: prevState.mouse_position!==id ? id: next_id
            }))
        }
    }

    render() {
        if(!this.state.loaded){
            return null
        }
        return <React.Fragment>
            <div className="widget-container">
                <div className="widget-container-buttons">
                    <Link to={'/edit'}><button className="edit"/></Link>
                </div>
            {this.state.widgets.map(widget => {
                let act_id = 'widget.' + widget.id
                let next_id = 'widget.' + ((this.state.widgets[this.state.widgets.indexOf(widget) + 1] || {}).id || widget.id)
                let deleted = this.state.deleted_user_widgets.indexOf(widget.id)!==-1
                return <React.Fragment>
                    {this.state.mouse_position == act_id ? <Widget {...{...this.state.widgets.find(widget => 'widget.'+widget.id==this.state.sorting), ...{data: undefined}, className:"new-place widget"}}/> : null}
                    <Widget {...widget} {...(deleted ? {className: 'removed-widget'}: {
                        ...(this.state.sorting===act_id ? {style: {display: 'none'}}: {}),
                        draggable:true,
                        onDragStart: this.dragMouseDown,
                        ...(this.state.edited_user_widgets[widget.id] ? {className: 'edited-widget'} : {})
                    })} onDragOver={() => this.mouseEntered(act_id,next_id)} id={act_id} key={act_id}/>
                </React.Fragment>
                })}
            </div>
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
