export function setToValue(obj, value, path) {
    for (var i = 0; i < path.length; i++){
        if(getObjectValue(obj, path.slice(0,i))===undefined){
            setToValue(obj, {},path.slice(0,i))
        }
    }
    for (var i = 0; i < path.length - 1; i++){
        obj = obj[path[i]];
    }
    obj[path[i]] = value;
}

export function getObjectValue(obj, path) {
    
    for (var i = 0; i < path.length; i++){
        if(obj==undefined){
            return undefined;
        }
        obj = obj[path[i]];
    }
    return obj;
}