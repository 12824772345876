import React, { Component } from 'react'
import { setGuide, setTitle } from "../actions"
import { connect } from "react-redux"
import AuthClient from '@sapkk/auth-client';
import SimpleField from '@sapkk/app/components/SimpleField';
import SelectField from '@sapkk/app/components/SelectField';
import PasswordChecker from '@sapkk/app/PasswordChecker';

class Profil extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            user_data: {},

            loaded: false,
            readOnly: true
        }
    }

    componentDidMount() {
        this.loadUserData()
    }

    loadUserData = () =>{
        AuthClient.getUserData().then(user_data =>{
            this.props.setTitle(user_data.username)
            this.props.setGuide(null)
            this.setState({
                user_data: user_data,
                original_user_data: user_data,
                loaded: true
            })
        })
    }

    save = (event)=>{
        event.preventDefault()
        this.setState({
            save_in_progress: true
        }, ()=>{
            this.setState({
                readOnly: true,
                save_in_progress: false
            })
        })
    }

    edit = ()=>{
        this.setState({
            readOnly: false
        })
    }

    cancel = ()=>{
        this.setState((prevState) =>({
            user_data: prevState.original_user_data,
            readOnly: true
        }))
    }

    change = (event) =>{
        let [name, type, checked, value] = [event.target.name, event.target.type, event.target.checked, event.target.value]
        this.setState((prevState) =>({
            user_data: {
                ...prevState.user_data,
                [name]: type === 'checkbox' ? checked : value
            }
        }))
    }
    
    render() {
        if(!this.state.loaded){
            return null
        }
        let labelClasses = ['label', 'label-long']
        let requiredLabelClasses = [...labelClasses]
        if(!this.state.readOnly){
            requiredLabelClasses.push('required')
        }

        let password_strongness = PasswordChecker.checkPasswordStrongness(this.state.user_data.new_password || '')
        let password_is_writed = (this.state.user_data.new_password || '') !== ''
        let notPasswordLabelClasses = [...labelClasses]
        if((password_is_writed || this.props.required) && !this.props.readOnly){
            notPasswordLabelClasses.push('required')
        }

        return (
            <React.Fragment>
                <form onSubmit={(event)=>this.save(event)} onChange={(event)=>this.change(event)}>
                <div className="page-subtitle">Felhasználói adatok</div>
                {
                    this.state.user_data.type !== "local" && <React.Fragment>
                        <div className="block">
                            <label className={(this.state.user_data.type !== "technical" ? labelClasses : requiredLabelClasses).join(' ')}>Felhasználói azonosító</label>
                            <SimpleField name="username" required={true} value={this.state.user_data.username} readOnly={this.state.readOnly || this.state.user_data.type !== "technical"} className={'field field-simple'} maxLength={255}/>
                        </div>
                    </React.Fragment>
                }            
                <div className="block">
                    <label className={(this.state.user_data.type === "ldap" ? labelClasses : requiredLabelClasses).join(' ')}>Teljes név</label>
                    <SimpleField name="full_name" required={true} value={this.state.user_data.full_name} readOnly={this.state.readOnly || this.state.user_data.type === "ldap"} className={'field field-simple'} maxLength={255}/>
                </div>
                {
                    this.state.user_data.type !== 'technical' && <React.Fragment>
                        <div className="block block-lg-1-2">
                            <label className={(this.state.user_data.type === "ldap" ? labelClasses : requiredLabelClasses).join(' ')}>E-mail-cím</label>
                            <SimpleField name="email" required={true} type="email" value={this.state.user_data.email} readOnly={this.state.readOnly || this.state.user_data.type === "ldap"} className={'field field-simple'} maxLength={255}/>
                        </div>
                        <div className="block block-lg-1-2">
                            <label className={labelClasses.join(' ')}>Telefonszám</label>
                            <SimpleField name="phone_number" value={this.state.user_data.phone_number} readOnly={this.state.readOnly} className={'field field-simple'} maxLength={255}/>
                        </div>
                    </React.Fragment>
                }
                <div className="page-subtitle">Felhasználó státusza</div>
                <div className="block block-lg-1-2">
                    <label className={"label label-long"}>Egyetemi dolgozó</label>
                    <SelectField className="field field-select" name="employee" readOnly={true} required={true} value={this.state.user_data.employee ? 'true' : 'false'} displayValues={{'false': 'Nem', 'true':'Igen'}}>
                        <option value="" disabled={true}>Kérem, válasszon!</option>
                        <option value="true"/>
                        <option value="false"/>
                    </SelectField>
                </div>
                {
                    this.state.user_data.employee && <React.Fragment>
                        <div className="block">
                            <label className={"label label-long"}>Szervezet</label>
                            <SimpleField className="field field-select" name="organization" readOnly={true} required={true} value={this.state.user_data.organization}/>
                        </div>
                        <div className="block">
                            <label className={"label label-long"}>Szervezeti egység</label>
                            <SimpleField className="field field-select" name="organization_unit" readOnly={true} required={true} value={this.state.user_data.organization_unit}/>
                        </div>
                    </React.Fragment>
                }
                {
                    !this.state.readOnly && this.state.user_data.type=='local' && <React.Fragment>
                        <div className="page-subtitle">Bejelentkezési adatok</div>
                        <div className="block block-lg-3-4">
                            <label className={labelClasses.join(' ')}>Jelszó</label>
                            <input autoComplete='off' className="field field-simple" id="new_password" name="new_password" type="password" value={this.state.user_data.new_password || ''} required={this.props.required} readOnly={this.props.readOnly}/>
                        </div>
                        <div className="block block-lg-1-4">{password_is_writed && <React.Fragment>
                            <div className={"password-info "+ password_strongness}>
                                {password_strongness=='weak' ? 'Gyenge' : (password_strongness=='acceptable' ? 'Közepes' : 'Erős')}
                            </div>
                        </React.Fragment>}
                        </div>
                        <div className="block block-lg-3-4">
                            <label className={notPasswordLabelClasses.join(' ')}>Jelszó ismétlése</label>
                            <input className="field field-simple" name="new_password_again" type="password" value={this.state.user_data.new_password_again || ''} required={this.props.required || password_is_writed} readOnly={this.props.readOnly}/>
                        </div>
                        <div className="block block-lg-1-4">
                            {password_is_writed && (this.state.user_data.new_password_again || '')!=='' && <React.Fragment>
                            <div className={"password-info "+ (this.state.user_data.new_password_again===this.state.user_data.new_password ? 'equivalent' : 'non-equivalent')}>
                                {this.state.user_data.new_password_again===this.state.user_data.new_password ? 'Megegyező' : 'Nem egyező'}
                            </div>
                            </React.Fragment>}
                        </div>
                    </React.Fragment>
                }
                <div className="actions">
                    {this.state.readOnly && <button onClick={this.edit}>Adatmódosítás</button>}
                    {!this.state.readOnly && <button disabled={this.state.save_in_progress} type="submit">Mentés</button>}
                    {!this.state.readOnly && <button disabled={this.state.save_in_progress} onClick={this.cancel}>Mégse</button>}
                </div>
            </form>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide
}

function mapStateToProps(state) {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Profil)
