import React, { Component } from 'react'
import { setGuide, setTitle, addMessage, clearMessages} from "../actions"
import { connect } from "react-redux"
import DashboardClient from '../DashboardClient';
import DateTimeField from '@sapkk/app/components/DateTimeField';
import Loading from "@sapkk/app/components/Loading"

class Systems extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            loaded: false,
            popup_system: null,
            hovered_info_system: null
        }
    }
    
    componentDidMount() {
        this.props.setTitle("Alkalmazások")
        this.props.setGuide(null)
    }

    handleDescriptionOpen = (system_id) => {
        this.setState({
            popup_system: system_id
        })
    }

    handleDescriptionClose = () => {
        this.setState({
            popup_system: null
        })
    }

    mouseEnter = (system_id, event) =>{
        event.stopPropagation()
        this.setState({
            hovered_info_system: system_id,
            hovered_info_system_bottom: document.body.getBoundingClientRect().height - event.target.getBoundingClientRect().top + document.body.getBoundingClientRect().top,
            hovered_info_system_right: document.body.getBoundingClientRect().width - event.target.getBoundingClientRect().right < 250 ? 212 :  document.body.getBoundingClientRect().right - event.target.getBoundingClientRect().right 
        })
    }

    mouseLeave = () => {
        this.setState({
            hovered_info_system: null
        })
    }

    render() {
        return (
            <React.Fragment>
                <div id="system-info-bubble" style={this.state.hovered_info_system ? {
                    bottom: this.state.hovered_info_system_bottom + 10,
                    right: this.state.hovered_info_system_right - 200,
                }: {display: 'none'}}>
                    {(this.props.systems[this.state.hovered_info_system]||{}).description}
                </div>
                        <div className="system widget-container">
                        {
                            this.props.systems_order.map(system_id =>{
                                let system = this.props.systems[system_id]
                                return <div className="widget system" style={{
                                    gridColumn: 'span 1',
                                    gridRow: 'span 1'
                                }} onClick={()=>{window.location.href= system.url}}>
                                    <div className="information-container">
                                        {system.description ? <div className="info" onClick={(event)=>this.mouseEnter(system_id, event)}  onMouseEnter={(event)=>this.mouseEnter(system_id, event)} onMouseLeave={this.mouseLeave}></div> : null}
                                    </div>
                                    <div className="widget-content">
                                    <div id ="dashboard" className="widget-dashboard">
                                        
                                        <div className="menu">
                                            <div className="dashboard-title">{system.alias}</div>
                                        </div>
                                    </div>
                                        
                                        </div>
                                </div>
                            })
                        }
                        </div>
                </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}

function mapStateToProps(state) {
    return {
        systems: state.systems.systems,
        systems_order: state.systems.systems_order
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Systems)
